<template>
  <div class="memberShip">
    <div class="shipContent content_area">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "申请入会",
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.memberShip {
  height: 100%;
  background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 24px 0 60px;
  .shipContent {
    background: #ffffff;
    padding: 47px 0 74px;
  }
}
</style>
